import React, { FC } from 'react';
import './CallToAction.scss';
import { ApiType } from '../../../../Model/ApiModel';
 
interface propsType {
  campaign: ApiType
}

const CallToAction:FC<propsType> = ({ campaign }) => {
   
  return (
    <div className='HomeFooter text-center px-0 w-100' style={{ backgroundColor: campaign.cta_bg_color??"", backgroundImage: `url(${campaign.cta_bg_image})`}}>
        <div className="inner-footer py-4 d-flex align-items-center ">
            <div className="mx-auto container">
                <h3 className="h3 pb-3">{campaign.cta_top_title}</h3>
                <h1 className="h1">{campaign.cta_title}</h1>
                <div className="btn-container mt-4">
                  <a href={campaign.cta_button_url}>
                    <button className="btn rounded-pill p-3 mx-auto" style={{backgroundColor: campaign.cta_button_bg}}>{campaign.cta_button_label}</button>
                  </a>
                </div>
            </div>
        </div> 
    </div>
  )
}

export default CallToAction