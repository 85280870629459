import React, { FC } from 'react';
import './FrequestlyAskedQuestion.scss';
import { ApiType } from '../../../../Model/ApiModel';


interface propsType {
    campaign: ApiType
  }
  
const FrequestlyAskedQuestion:FC<propsType> = ({ campaign }) => {
   

  return (
    <div className='FrequestlyAskedQuestion py-5' style={{backgroundColor: campaign.faq_section_bg_color}}>
        <div className="container">
            <h1 className="h1 text-center pb-5">{campaign.faq_section_title}</h1>
            <div className="row w-100 mx-auto">
                <div className="col-lg-6 p-md-3">
                    
                    <div className="text-cont p-md-3 text-lg-start mb-3 ">
                        <div className="heading">{campaign.faq_section_question}</div>
                        <div className="texts mt-2 pb-4">{campaign.faq_section_answer}</div>
                    </div>
                    
                    <div className="text-cont p-md-3 text-lg-start mb-3 ">
                        <div className="heading">{campaign.faq_section_question_2}</div>
                        <div className="texts mt-2 pb-4">{campaign.faq_section_answer_2}</div>
                    </div>
                    
                    <div className="text-cont p-md-3 text-lg-start mb-3 ">
                        <div className="heading">{campaign.faq_section_question_3}</div>
                        <div className="texts mt-2 pb-4">{campaign.faq_section_answer_3}</div>
                    </div>
                    
                    <div className="text-cont p-md-3 text-lg-start mb-3 ">
                        <div className="heading">{campaign.faq_section_question_4}</div>
                        <div className="texts mt-2 pb-4">{campaign.faq_section_answer_4}</div>
                    </div>                
                </div>
                <div className="col-lg-6 p-md-3 row mx-auto">

                    <div className="img-cont col-lg-6 p-md-3 py-3 d-flex">
                        <img src={campaign.faq_section_image_1} className='w-100 h-100' alt="" />
                    </div>
                    
                    <div className="img-cont col-lg-6 p-md-3 py-3 d-flex">
                        <img src={campaign.faq_section_image_2} className='w-100 h-100' alt="" />
                    </div>
                    
                    <div className="img-cont col-lg-12 p-md-3 py-3 d-flex">
                        <img src={campaign.faq_section_image_3} className='w-100 h-100' alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FrequestlyAskedQuestion