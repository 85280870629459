import axios from "axios"
import { AxiosInterceptor } from '../Config/axiosInterceptor'

AxiosInterceptor()

export const apiService = {
    index: async (campaign_id: string|undefined) =>{
      return await axios.get(`/ads/${campaign_id}`)
    }
}
 