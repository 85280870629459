import React, { FC } from 'react';
import './HomeIntroTop.scss'
import { ApiType } from '../../../../Model/ApiModel';

interface propsType {
  campaign: ApiType
}

const HomeIntroTop:FC<propsType> = ({campaign}) => {
 
  return (
    <div className='HomeIntroTop' style={{backgroundColor: campaign.intro_section_bg_color}}>
      <div className="container">
        <h3 className='intro-header text-center'>
          <span className='pe-1'>{campaign.intro_sub_top_title}</span>
          <div className='blue-text d-inline ps-1'>{campaign.company}</div>
        </h3> 
        <h1 className='text-center pt-2 future-of-eBIKe mb-0'>{campaign.intro_title} <span className='blue-text d-none'>FUTURE</span></h1>
      </div>
    </div>
  )
}

export default HomeIntroTop;