import { ApiType } from '../../../../Model/ApiModel';
import './HomeIntro.scss';
import React, { FC } from 'react'

interface propsType {
  campaign: ApiType
}
 
const HomeIntro:FC<propsType> = ({ campaign }) => {
 

  return (
    <div className='HomeIntro pb-5' style={{backgroundColor: campaign.intro_section_bg_color}}>
      <div className='container'>
        <div className='row w-100 mx-auto align-items-center justify-content-center  mx-auto'>
          <div className='col-lg-6 pe-lg-5'>  
            <img src={campaign.intro_left_image} className='w-100 h-100' alt={campaign.intro_left_image}/>
          </div>
          <div className='col-lg-6 ps-lg-5 pt-4 pt-md-5 pt-lg-0 text-column'>
            <h1 className='h1 p-0'>{campaign.intro_right_title}
            <br className='d-none d-lg-block'/> 
              {/* eBIKe is the <span className='blue-text'>Best Electric 
                <br className='d-none d-lg-block'/> 
                Bike </span> for Commuting and Recreation */}
            </h1> 
            <div className="stars-container py-2">
              <span className="stars pe-1">★</span>
              <span className="stars pe-1">★</span>
              <span className="stars pe-1">★</span>
              <span className="stars pe-1">★</span>
              <span className="stars pe-1">★</span>
              <span className="review-link-container ps-2">
                  <a href="/#" className='review-link'>Read Reviews</a>
              </span>
            </div>
            <div className="text-descr">
              {campaign.intro_right_excerpt}
            </div>
            <div className="btn-container mt-4">
              <a href={campaign.intro_button_url}>
                <button className="btn rounded-pill" style={{backgroundColor: campaign.intro_button_bg}}>
                  {campaign.intro_button_label}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeIntro