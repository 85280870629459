import React, { Fragment, useEffect, useState } from 'react';
import './Campaign.scss';
import {  
  FrequestlyAskedQuestion, 
  AboutProduct, 
  CallToAction, 
  HomeIntroTop, 
  Review, 
  HomeIntro,
  Products,
  IntroVideo
} from '../../Components'; 
import { apiService } from '../../../../Service';
import { ApiType } from '../../../../Model/ApiModel';
import FooterCredit from '../../../../Layout/Footer/FooterCredit';
import { useParams } from 'react-router-dom';

const Campaign = () => { 
  const [campaign, setCampaign] = useState<ApiType>(); 
  const [request, setRequest] = useState<boolean>(false); 
  const { campaign_id  }  = useParams<{campaign_id: string}>()

  

  const getCampaign = async () => {
    setRequest(true)
    await apiService.index(campaign_id).then((res) => {
      console.log("res  009 4", res.data.data)
      setCampaign(res.data.data) 
      setRequest(false)
    }, (err) => { 
      setRequest(false)
      console.log("apiService error ", err) 
      window.location.href = "/"
    })
  }

  useEffect(() => {
    getCampaign()
  }, [])

  return (
    <div className='Campaign'>
      {request && <div className={`Loader-cont d-block w-100 d-flex justify-content-center align-items-center`}>
        <span className="spinner-border"></span>
      </div>} 
      {!request && campaign && <Fragment>
         <HomeIntroTop campaign={campaign}/> 
        <HomeIntro campaign={campaign}/>
        {Number(campaign.video_section_hide) === 0 && <IntroVideo campaign={campaign}></IntroVideo>}
        {Number(campaign.action_hide) === 0 &&<AboutProduct campaign={campaign}/>}
        {Number(campaign.product_section_hide) ===0 && <Products campaign={campaign}></Products>}
        {Number(campaign.review_section_hide) ===0 &&<Review campaign={campaign} />}
         {Number(campaign.faq_section_hide) ===0 &&<FrequestlyAskedQuestion campaign={campaign}/>}
         <CallToAction campaign={campaign}/>
        <FooterCredit campaign={campaign}></FooterCredit>
      </Fragment>}
      
    </div>
  )
}

export default Campaign;