import React from 'react'

const Home = () => {
    return (
        <div className='container vh-100'>
             <div className="row h-100 justify-content-center">
                <div className="col-md-6 my-auto">
                    <div className="card">
                        <div className="card-body text-center">
                            /index: This page is empty
                        </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default Home
