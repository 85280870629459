import React, { FC } from 'react'
import { ApiType } from '../../Model/ApiModel'
import "./FooterCredit.scss"

interface propsType {
    campaign: ApiType
  }
  
  const FooterCredit:FC<propsType> = ({ campaign }) => {
 
    return ( 
        <div className="footer-credit dark-copyright-footer-section py-4 px-3 text-center">
             @{campaign.copyright_year} {campaign.copyright_title}. All rights reserved.  <a href={campaign.copyright_privacy_policy_url}>Privacy Policy</a>  |  Created by <a href="https://surfclickinnovations.com/">{campaign.company}</a>,  {campaign.copyright_extras}
        </div> 
    )
}

export default FooterCredit
