import React, { FC } from 'react';
import './Review.scss';
import { ApiType } from '../../../../Model/ApiModel';

interface propsType {
    campaign: ApiType
  }
const Review:FC<propsType> = ({ campaign }) => { 

  return (
    <div className='Review ' style={{backgroundColor: campaign.review_section_bg_color}}>
        <div className="container">
            <h1 className="h1 text-center">{campaign.review_section_title}</h1>
            <div className="row mx-auto w-100 px-sm-3 mt-4 justify-content-center">

                <div className="col-lg-4 col-sm-8 px-md-4 mx-auto text-center">
                    <div className="stars-container text-center mt-3 mt-lg-4">
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                    </div>
                    <div className="texts mt-2">
                        {campaign.review_content}
                    </div>
                    <div className="name mt-3">{campaign.review_author}</div>
                </div>
                
                <div className="col-lg-4 col-sm-8 px-md-4 mx-auto text-center">
                    <div className="stars-container text-center mt-3 mt-lg-4">
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                    </div>
                    <div className="texts mt-2">
                    {campaign.review_content_2}
                    </div>
                    <div className="name mt-3">{campaign.review_author_2}</div>
                </div>
                
                <div className="col-lg-4 col-sm-8 px-md-4 mx-auto text-center">
                    <div className="stars-container text-center mt-3 mt-lg-4">
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                        <span className="stars pe-1">★</span>
                    </div>
                    <div className="texts mt-2">
                    {campaign.review_content_3}
                    </div>
                    <div className="name mt-3">{campaign.review_author_3}</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Review