import React, { FC } from 'react';
import "./Products.scss";
import { ApiType, ProductType } from '../../../../Model/ApiModel';

interface PropsType {
    campaign: ApiType;
}

const Products: FC<PropsType> = ({ campaign }) => {
    return ( 
            <div className='Products' style={{ backgroundColor: campaign.product_section_bg }}>
                <div className="section-title text-center">
                    <h2>Explore Our Latest Collections</h2>
                </div>
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        {campaign.products && campaign.products.map((product: ProductType, index: number) => (
                            <div className="col-md-4 mt-3" key={index}>
                                <div className="card">
                                    <div className="card-image">
                                        <img src={product.product_image} alt={product.product_name} title={product.product_name} className='w-100'/>
                                    </div>
                                    <div className="card-body">
                                        <div className="title">
                                            {product.product_name}
                                        </div>
                                        <div className="price">
                                            ₦{product.product_price}
                                        </div>
                                        <div className="description mb-4">
                                            {product.product_description}
                                        </div>
                                        <a href={product.product_button_url} className='mt-4'>
                                            <button className='btn btn-dark' style={{ backgroundColor: product.product_button_bg }}>
                                                ₦{product.product_price} {product.product_button_label}
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ) 
};

export default Products;
