import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import './index.scss'; 
import reportWebVitals from './reportWebVitals';
import { Campaign } from './Pages/Frontend/Interface';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Frontend/Interface/Home/Home';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>  
  <BrowserRouter> 
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/:campaign_id' element={<Campaign />} />
    </Routes>
  </BrowserRouter> 
</React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
