import axios from 'axios'    
 export const AxiosInterceptor = () => {

    let baseUrl = process.env.REACT_APP_BASEURL 
    axios.defaults.baseURL = baseUrl;

    axios.defaults.headers.post['Content-Type'] = 'application/json'; 

    axios.interceptors.request.use(function (config) {
      
        config.headers['Access-Control-Allow-Origin'] = '*'; 
 
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
  

    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) { 
      return Promise.reject(error);
    });
}
  
 
