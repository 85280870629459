import React, { FC } from 'react';
import './AboutProduct.scss'
import { ApiType } from '../../../../Model/ApiModel';

interface propsType {
    campaign: ApiType
  }
  
const AboutProduct:FC<propsType> = ({ campaign }) => {
    
  return (
    <div className='AboutProduct' style={{backgroundColor: campaign.action_section_bg_color}}>
        <div className="container">
            <div className="row mx-auto w-100 py-5 px-md-3 justify-content-center">

                <div className="col-lg-4 col-sm-8 px-md-4 mx-auto text-center py-4">
                    <h1 className="h1">{ campaign.action_section_title}</h1>
                    <h3 className="h3 py-3 bluewish-text">{campaign.action_section_sub_title}</h3>
                    <div className="texts">
                        {campaign.action_section_content}
                    </div>
                </div>
 
                
                <div className="col-lg-4 col-sm-8 px-md-4 mx-auto text-center py-4">
                    <h1 className="h1">{campaign.action_section_title_2}</h1>
                    <h3 className="h3 py-3 bluewish-text">{campaign.action_section_sub_title_2}</h3>
                    <div className="texts">
                        {campaign.action_section_content_2}
                    </div>
                </div>

                
                <div className="col-lg-4 col-sm-8 px-md-4 mx-auto text-center py-4">
                    <h1 className="h1">{campaign.action_section_title_3}</h1>
                    <h3 className="h3 py-3 bluewish-text">{campaign.action_section_sub_title_3}</h3>
                    <div className="texts">
                        {campaign.action_section_content_3}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutProduct