import React, { FC, Key } from 'react'
import "./IntroVideo.scss"
import { ApiType } from '../../../../Model/ApiModel'

interface propsType {
    campaign: ApiType
  }
   
  const IntroVideo:FC<propsType> = ({ campaign }) => { 
    return ( campaign && 
        <div className='IntroVideo pb-5' style={{backgroundColor: campaign.video_section_bg}}>
            <div className="sction-tile text-center">
                <h2>{campaign.video_section_title}</h2>
            </div>
            <div className="container mt-1">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card"> 
                            <div className="card-body">
                                 <iframe className='w-100' height="485" src={campaign.video_section_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div> 
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default IntroVideo
